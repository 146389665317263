<template>
  <div v-if="countriesAll.length">
    <div  v-if='!showThankyouMessage'>
      <div class="m-auto mb-8">
        <table class="min-w-full divide-y divide-gray-300 border-b border-b-gray-200">
          <thead>
            <tr>
              <th scope="col" colspan="2"
                class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Payment Overview
              </th>
            </tr>
          </thead>
          <tbody class="bg-white ">
            <tr v-for="(lineItem, index) in lineItems" :key="index" :class="lineItem.cssClass">
              <td class="py-2 pl-4 pr-3 text-sm text-gray-500">{{ lineItem.name }}</td>
              <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium">{{ lineItem.amountString }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if='!!companyProfile.tbsCompanyId' class="m-auto font-bold">
        <p class="my-6 text-sm text-gray-700 text-center">
          After successful payment, your account will be activated immediately. We use Mollie as our payment solution which supports all major payment options.
        </p>
        <!-- <ul role="list" class="grid grid-cols-3 gap-2 sm:grid-cols-5 ">
          <li v-for='item in paymentProviders' :key='item.imgUrl'
            class="col-span-1 divide-y divide-gray-200 rounded-sm bg-white shadow p-2" :title="item.alt">
            <div class='paymentIcon bg-no-repeat bg-contain bg-center' :style="{ backgroundImage: `url(${item.imgUrl})` }">
            </div>
          </li>
        </ul>
        <p class="my-6 text-sm leading-5 text-gray-600">Upon completion of the order, your credit card will be charged
          immediately.</p> -->
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button @click='prev' type="button"
            class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Back
            to Attendees</button>
          <button v-if="!!companyProfile.tbsCompanyId" @click='next' type="button"
            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Register and Pay</button>
          <button v-else @click='next' type="button"
            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Register now</button>
        </div>
      </div>
    </div>
    <div v-else>
      <span v-html="regThankyouText"></span>

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex';

import eventBus from "../utils/eventBus";
import payVisa from "../assets/pay/pay_visa.png"
import payAmex from "../assets/pay/pay_amex.png"
import payMc from "../assets/pay/pay_mc.png"
import payBancontact from "../assets/pay/pay_bancontact.png"
import payMaestro from "../assets/pay/pay_maestro.png"
import payIdeal from "../assets/pay/pay_ideal.png"
import payGiropay from "../assets/pay/pay_giropay.png"
import payBelfius from "../assets/pay/pay_belfius.png"
import payKbc from "../assets/pay/pay_kbc.png"
import payCbc from "../assets/pay/pay_cbc.png"
import paySofosDe from "../assets/pay/pay_sofos_de.png"
import paySofosAt from "../assets/pay/pay_sofos_at.png"
import paySofosNl from "../assets/pay/pay_sofos_nl.png"
import paySofosBe from "../assets/pay/pay_sofos_be.png"
import paySofosIt from "../assets/pay/pay_sofos_it.png"

export default {
  name: "RegisterStep2",
  props: {
    companyProfile: Object,
    attendees: Array,
  },

  data() {
    return {
      paymentProviders: [
        { imgUrl: payVisa, alt: 'VISA' },
        { imgUrl: payAmex, alt: 'American Express' },
        { imgUrl: payMc, alt: 'MasterCard' },
        { imgUrl: payBancontact, alt: 'Bancontact/Mister Cash' },
        { imgUrl: payMaestro, alt: 'Maestro' },
        { imgUrl: payIdeal, alt: 'iDEAL' },
        { imgUrl: payGiropay, alt: 'giropay' },
        { imgUrl: payBelfius, alt: 'Belfius Direct Net' },
        { imgUrl: payKbc, alt: 'KBC Bank' },
        { imgUrl: payCbc, alt: 'CBC Banque' },
        { imgUrl: paySofosDe, alt: 'Sofortï¿½berweisung DE' },
        { imgUrl: paySofosAt, alt: 'Sofortï¿½berweisung AT' },
        { imgUrl: paySofosNl, alt: 'Sofort Banking NL' },
        { imgUrl: paySofosBe, alt: 'Sofort Banking BE' },
        { imgUrl: paySofosIt, alt: 'Sofort Banking IT' },
      ],
      prices: {
        membership: 675,
        attendence: 895,
      },
      showThankyouMessage: false,
      regThankyouText: null,
    };
  },
  computed: {
    ...mapState(['countriesAll']),
    lineItems() {

      const items = []
      if (!this.companyProfile.tbsCompanyId) {
        items.push({
          name: 'TBS Plus Membership (1 year)',
          amount: this.prices.membership,
        })
      }
      _.each(this.attendees, attendee => {
        items.push({
          name: `EBM Attendance for ${attendee.name} (${attendee.email})`,
          amount: this.prices.attendence,
        })
      })

      let vat = 0
      let vatDescription = 'VAT Total'

      const nl = _.find(this.countriesAll, { country: 'Netherlands' }).country_id
      const reverseChargeCountryCodes = _.map(_.filter(this.countriesAll, { reverse_charge: 1 }), 'country_id')

      if (this.companyProfile.country === nl) {
        vat = 21
        vatDescription = 'VAT Total (21% VAT)'
      } else if (~reverseChargeCountryCodes.indexOf(this.companyProfile.country)) {
        vatDescription = 'VAT Total (Reverse Charge)'
      }

      const subTotal = _.sumBy(items, 'amount')
      const vatTotal = subTotal * (vat / 100)
      const grandTotal = subTotal + vatTotal

      items.push({ name: 'Subtotal', amount: subTotal, cssClass: "border-t border-t-gray-200" })
      items.push({ name: vatDescription, amount: vatTotal })
      items.push({ name: 'Total', amount: grandTotal, cssClass: "border-double border-t-4 border-t-gray-200" })

      _.each(items, item => {
        item.amountString = `€ ${item.amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
      })
      return items
    }
  },
  async created() {
    this.payload = _.cloneDeep(this.companyProfile)

    try {
      this.allSettings = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload)
      this.regThankyouText = this.allSettings.reg_thankyou
    } catch (err) {
      console.log("err", err)
    }
  },
  methods: {
    ...mapActions(["register"]),
    async next() {

      let dlPushAction = 'Registration INITIATED'
      let pushMessage = this.companyProfile.tbsCompanyId ? 'Registration by a TBS Member' : 'Registration by a non-member';
      this.pushDataLayer( 'ebmRegStart', 'buttRegister', dlPushAction, pushMessage )

      const payload = {
        companyProfile: this.companyProfile,
        attendees: this.attendees,
        utmParams: localStorage.getItem('utmParams'),
        refParams: localStorage.getItem('refParams'),
      }

      try {
        // Register
        const response = await this.register(payload)
        // registration is complete, we need to push to the Google tag manager
        let dlPushAction = 'Registration COMPLETED'
        let pushMessage = this.companyProfile.tbsCompanyId ? 'Registration by a TBS Member' : 'Registration by a non-member';
        this.pushDataLayer( 'ebmRegEnd', 'buttRegister', dlPushAction, pushMessage )

        // When all good, redirect to payment
        // only redirect to payment if this is a TBS member, non-TBS members will not be redirected but shown the thank you page
        if (this.companyProfile.tbsCompanyId) {window.location.href = response.link}
        else {this.showThankyouMessage = true}

      } catch (err) {
        let dlPushAction = 'Registration Incomplete (failed)'
        let pushMessage = this.companyProfile.tbsCompanyId ? 'Registration by a TBS Member' : 'Registration by a non-member';
        this.pushDataLayer( 'ebmRegError', 'buttRegister', dlPushAction, pushMessage )

        this.showThankyouMessage = false
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Error while registering",
          body: err.message,
          timeoutInMS: 5000,
        });

      }
    },
    async prev() {
      this.$parent.$parent.toStep({ step: 4, companyProfile: this.payload })
    },
    pushDataLayer( event, buttonId, action, message ) {
        window.dataLayer.push({
          'event': event, // A custom event name, e.g., 'signupButtonClick'
          'buttonId': buttonId, // Optional: ID or any identifier of the button
          'action': action, // Optional: More details about the action
          'message': message,
          // You can add more properties here as needed
        })
    },
  },

};
</script>
<style>
.paymentIcon {
  height: 45px
}
</style>
